import React, { Component } from 'react'
import Script from 'react-load-script'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Blocks from '../components/Blocks'
import { ServicesIntro } from '../components/ServicesIntro'
import { CurveSection } from '../components/CurveSection'

import { Layout as _Layout, Color } from '../constants'

const styles = {
  home: {
    marginBottom: _Layout.EDGE_PADDING * 2,
  },
  introContainer: {
    marginTop: _Layout.EDGE_PADDING * 3,
    marginBottom: _Layout.EDGE_PADDING * 3,
  },
  heading: {
    fontSize: _Layout.TITLE + 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: _Layout.EDGE_PADDING * 3,
    paddingLeft: _Layout.EDGE_PADDING,
    paddingRight: _Layout.EDGE_PADDING,
    maxWidth: 800,
    margin: 'auto',
  },
}

const HomePageTemplate = ({ hero, intro, main, form, servicesIntro }) => {
  return (
    <>
      <Hero home {...hero} />
      <div style={styles.home}>
        <section style={styles.introContainer}>
          <h2 style={styles.heading}>{get(intro, 'heading')}</h2>
          <Blocks blurbs={get(main, 'blurbs')} />
        </section>
        <CurveSection
          padding={8}
          render={() => <ServicesIntro {...servicesIntro} background="dark" />}
        />
      </div>
    </>
  )
}

class HomePage extends Component {
  handleScriptLoad = () => {
    if (window.netlifyIdentity) {
      window.netlifyIdentity.on('init', user => {
        if (!user) {
          window.netlifyIdentity.on('login', () => {
            document.location.href = '/admin/'
          })
        }
      })
    }
    window.netlifyIdentity.init()
  }

  render() {
    const {
      data: { footerData, navbarData, homePageData, servicesIntroData },
    } = this.props
    const frontmatter = get(homePageData, 'edges[0].node.frontmatter')
    const servicesData = get(servicesIntroData, 'edges[0].node.frontmatter')
    const { hero, intro, main, form } = frontmatter

    return (
      <Layout footerData={footerData} navbarData={navbarData}>
        <HomePageTemplate
          hero={hero}
          intro={intro}
          main={main}
          form={form}
          servicesIntro={servicesData}
        />
        <Script
          url="https://identity.netlify.com/v1/netlify-identity-widget.js"
          onLoad={this.handleScriptLoad}
        />
      </Layout>
    )
    // return <UnderConstruction />
  }
}

export default HomePage

export const pageQuery = graphql`
  query HomePageQuery {
    ...LayoutFragment
    ...ServicesIntroFragment
    homePageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            templateKey
            hero {
              title
              text
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              type
              button
            }
            intro {
              heading
              blurbs {
                icon
                title
                description
              }
            }
            main {
              blurbs {
                image {
                  childImageSharp {
                    fluid(maxWidth: 526, quality: 92) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
                description
                title
              }
            }
            form {
              title
              text
              phoneNumber
            }
          }
        }
      }
    }
  }
`

export { HomePageTemplate, HomePage }
