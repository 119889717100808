import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import { Color, Layout } from '../constants'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const styles = {
  home: {
    marginBottom: Layout.EDGE_PADDING * 2,
  },
  introContainer: {
    marginBottom: Layout.EDGE_PADDING * 3,
  },
  heading: {
    fontSize: Layout.TITLE + 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: Layout.EDGE_PADDING * 3,
  },
  rowSection: {
    paddingTop: Layout.EDGE_PADDING * 4,
    paddingBottom: Layout.EDGE_PADDING * 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: Layout.TITLE + 8,
    fontWeight: 'bold',
  },
  image: {
    paddingLeft: Layout.EDGE_PADDING,
    paddingRight: Layout.EDGE_PADDING,
    width: '100%',
    maxWidth: 550,
  },
}

const Blocks = ({ blurbs }) => (
  <div className={css(styles.wrapper)}>
    {blurbs &&
      blurbs.map((item, i) => (
        <div key={item.title}>
          <div
            className={css(styles.rowSection, {
              backgroundColor: Color.WHITE,
              '@media (min-width: 840px)': {
                flexDirection: i % 2 ? 'row-reverse' : 'row',
              },
            })}
          >
            <article className={css(styles.image)}>
              <PreviewCompatibleImage imageInfo={item.image} />
            </article>
            <div
              className={css({
                '@media (min-width: 840px)': {
                  paddingTop: 0,
                  paddingRight:
                    i % 2 ? Layout.EDGE_PADDING * 4 : Layout.EDGE_PADDING,
                  paddingLeft:
                    !i % 2 ? Layout.EDGE_PADDING * 4 : Layout.EDGE_PADDING,
                },
                maxWidth: 550,
                paddingTop: Layout.EDGE_PADDING * 2,
                paddingRight: Layout.EDGE_PADDING,
                paddingLeft: Layout.EDGE_PADDING,
              })}
            >
              <h2 className={css(styles.title)}>{item.title}</h2>
              <div>{item.description}</div>
            </div>
          </div>
        </div>
      ))}
  </div>
)

Blocks.propTypes = {
  blurbs: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default Blocks
